import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import ConfigForm from '../config-form';
import ConfigCustomerTab from '../config-customer-tab';
import ConfigFormaGiftBack from '../config-form-giftback';
import ConfigIntegrationsTab from '../config-integrations-tab';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'store_data',
    label: 'Dados da Empresa',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'giftbacks',
    label: 'Giftbacks',
    icon: <Iconify icon="solar:notes-bold" width={24} />,
  },
  {
    value: 'clients',
    label: 'Clientes',
    icon: <SvgColor src="/assets/icons/navbar/ic_customer.svg" sx={{ width: 24, height: 24 }} />,
  },
  {
    value: 'integrations',
    label: 'Integrações',
    icon: <Iconify icon="solar:share-bold" width={24} />,
  },
];

type TConfigTab = (typeof TABS)[number]['value'];

// ----------------------------------------------------------------------

type Props = {
  id?: string;
};

// ----------------------------------------------------------------------

export default function ConfigRootView({ id = '1' }: Props) {
  const [enableForm, setEnableForm] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const settings = useSettingsContext();

  const [currentTab, setCurrentTab] = useState<TConfigTab>('store_data');

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue);
      setSearchParams({ tab: newValue });
    },
    [setSearchParams]
  );

  useEffect(() => {
    const tab = searchParams.get('tab') as TConfigTab | null;
    if (tab && TABS.some((t) => t.value === tab)) {
      setCurrentTab(tab);
    } else {
      setCurrentTab('store_data');
    }
  }, [searchParams]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Configurações"
        links={[{ name: 'Home', href: paths.dashboard.root }, { name: 'Configurações' }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Tabs
        value={currentTab}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleChangeTab}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>

      {currentTab === 'store_data' && (
        <ConfigForm
          enableForm={enableForm}
          onEnableForm={() => setEnableForm(true)}
          onDisableForm={() => setEnableForm(false)}
        />
      )}

      {currentTab === 'giftbacks' && <ConfigFormaGiftBack />}

      {currentTab === 'clients' && <ConfigCustomerTab />}

      {currentTab === 'integrations' && <ConfigIntegrationsTab />}
    </Container>
  );
}

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/use-boolean';

import { useGetZapiIntegrationQuery, useGetStoreIntegrationsQuery } from 'src/graphql/generated';

import { CardIntegration } from './components/card-integration';
import { ConfigTrayModal } from './components/config-tray-modal';
import { ConfigZapiModal } from './components/config-zApi-modal';
import { ConfigOmieModal } from './components/config-omie-modal';
import { ConfigWhatsappModal } from './components/config-whatsapp-modal';
import { ConfigSMSInfobipModal } from './components/config-smsinfobip-modal';

// ----------------------------------------------------------------------

export default function ConfigIntegrationsTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const whatsappModal = useBoolean();
  const trayModal = useBoolean();
  const zapiModal = useBoolean();
  const omieModal = useBoolean();
  const smsInfobipModal = useBoolean();

  const { data: integrationData, refetch } = useGetStoreIntegrationsQuery();
  const { data: zapiData, refetch: refetchZapi } = useGetZapiIntegrationQuery();

  const integratedTray = Boolean(integrationData?.infoIntegration?.traystoreintegration?.connected);
  const integratedOmie = Boolean(integrationData?.infoIntegration?.omiestoreintegration?.activated);
  const integratedZapi = Boolean(zapiData?.getZApiIntegration?.connected);
  const integratedSMSInfobip = Boolean(
    integrationData?.infoIntegration?.smsinfobipintegration?.activated
  );

  const currentDataTray = integrationData?.infoIntegration?.traystoreintegration;
  const currentDataWhatsapp = integrationData?.infoIntegration?.whatsappconfig;
  const currentDataOmie = integrationData?.infoIntegration?.omiestoreintegration;
  const currentDataZapi = zapiData?.getZApiIntegration;
  const currentDataSMSInfobip = integrationData?.infoIntegration?.smsinfobipintegration;

  const essentialWppFields = {
    whatsappAccessToken: currentDataWhatsapp?.whatsappAccessToken || '',
    phoneNumberId: currentDataWhatsapp?.phoneNumberId || '',
    whatsappAppBusinessId: currentDataWhatsapp?.whatsappAppBusinessId || '',
    whatsappBusinessAccountId: currentDataWhatsapp?.whatsappBusinessAccountId || '',
  };

  const verifyIfWppNotEmpty = Object.values(essentialWppFields || {}).every(
    (value) => value !== ''
  );

  const handleCloseModal = (modalName: string) => {
    switch (modalName) {
      case 'tray':
        trayModal.onFalse();
        break;
      case 'omie':
        omieModal.onFalse();
        break;
      case 'whatsapp':
        whatsappModal.onFalse();
        break;
      case 'zapi':
        zapiModal.onFalse();
        break;
      case 'smsinfobip':
        smsInfobipModal.onFalse();
        break;
      default:
        break;
    }
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('modal');
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    const modal = searchParams.get('modal');
    switch (modal) {
      case 'tray':
        if (!trayModal.value) trayModal.onTrue();
        break;
      case 'omie':
        if (!omieModal.value) omieModal.onTrue();
        break;
      case 'whatsapp':
        if (!whatsappModal.value) whatsappModal.onTrue();
        break;
      case 'zapi':
        if (!zapiModal.value) zapiModal.onTrue();
        break;
      case 'smsinfobip':
        if (!smsInfobipModal.value) smsInfobipModal.onTrue();
        break;
      default:
        break;
    }
  }, [searchParams, trayModal, omieModal, whatsappModal, zapiModal, smsInfobipModal]);

  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        <CardIntegration
          logoURL="/assets/images/tray-logo.png"
          heading="Tray"
          subheading="Integração com a conta da tray"
          isConnected={integratedTray}
          hasData={Boolean(currentDataTray)}
          onAction={trayModal.onTrue}
        />

        <CardIntegration
          logoURL="/assets/images/omie-logo.png"
          heading="Omie"
          subheading="Integração com a conta da Omie"
          isConnected={integratedOmie}
          hasData={Boolean(currentDataOmie)}
          onAction={omieModal.onTrue}
        />

        <CardIntegration
          logoURL="/assets/images/whatsapp-logo.png"
          heading="WhatsApp Business"
          subheading="Integração com a conta do WhatsApp"
          isConnected={verifyIfWppNotEmpty}
          hasData={Boolean(verifyIfWppNotEmpty)}
          onAction={whatsappModal.onTrue}
        />

        <CardIntegration
          logoURL="/assets/zapi-dark.png"
          heading="Z-Api"
          subheading="Integração com a conta do Z-Api"
          isConnected={integratedZapi}
          hasData={Boolean(currentDataZapi)}
          onAction={zapiModal.onTrue}
        />

        <CardIntegration
          logoURL="/assets/images/sms-logo.png"
          heading="Infobip"
          subheading="Integração com o serviço da Infobip"
          isConnected={integratedSMSInfobip}
          hasData={Boolean(currentDataSMSInfobip)}
          onAction={smsInfobipModal.onTrue}
        />
      </Box>
      {/* // ---------------- Modais --------------- */}
      <ConfigTrayModal
        open={trayModal.value}
        onClose={() => handleCloseModal('tray')}
        currentData={currentDataTray}
        disableForm={Boolean(currentDataTray)}
        refetch={refetch}
      />
      <ConfigOmieModal
        open={omieModal.value}
        onClose={() => handleCloseModal('omie')}
        currentData={currentDataOmie}
        disableForm={Boolean(currentDataOmie)}
        refetch={refetch}
      />
      <ConfigWhatsappModal
        open={whatsappModal.value}
        onClose={() => handleCloseModal('whatsapp')}
        // @ts-ignore
        currentData={currentDataWhatsapp}
        disableForm={Boolean(currentDataWhatsapp)}
        refetch={refetch}
        hasData={verifyIfWppNotEmpty}
      />
      <ConfigZapiModal
        open={zapiModal.value}
        onClose={() => handleCloseModal('zapi')}
        currentData={currentDataZapi}
        refetch={refetchZapi}
      />
      <ConfigSMSInfobipModal
        open={smsInfobipModal.value}
        onClose={() => handleCloseModal('smsinfobip')}
        currentData={currentDataSMSInfobip}
        refetch={refetch}
      />
    </>
  );
}
